<section class="course__area bg-gray">
  <div class="container pt-50 pb-50">
    <div class="row align-items-center">
      <div class="col-xxl-12 col-xl-12 col-lg-12 text-center pb-50">
        <div class="section__title-wrapper">
          <h2 class="section__title text-primary">Our <span class="yellow-bg yellow-bg-big">Service<img
                src="assets/img/shape/yellow-bg.png" alt=""></span></h2>
          <h3>Improve Your Skills</h3>
          <!--<h4 class="mt-15 font-weight-500 pb-0 mb-0">Embark on Your Learning Journey with Our Comprehensive Basic to
            Advanced and Mentorship Programs</h4>-->
        </div>
      </div>
    </div>
    <div class="row mb-0">
      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
        <div class="course__item course__item-2 white-bg transition-3 shadow-lg">
          <!--          <div class="course__thumb fix w-img">-->
          <!--            <a [routerLink]="['/forecasting']">-->
          <!--              <img src="assets/img/course/home-3/course-1.jpg" alt="">-->
          <!--            </a>-->
          <!--          </div>-->
          <div class="course__content-2">
            <h3 class="course__title-2"><a [routerLink]="['/plugin-trading']">NRT Plugin – Master the Art of Market
                Discipline!</a></h3>
            <h4 class="text-secondary">Stay Focused. Stay Disciplined. Stay Profitable.</h4>
            <p class="mt-15">
              In trading, discipline is the difference between winning and losing. The NRT Plugin is designed to
              reinforce your trading strategy, eliminate emotional decision-making, and keep you aligned with your
              trading style—whether it's Scalping, Intraday, Swing, Positional trading or Playing different F&O
              Strategies.
            </p>

            <h5 class="text-secondary"> Key Benefits of the NRT Plugin:</h5>
            <ul>
              <li>💡 <b> Eliminates Strategy Drift </b>– No more switching styles under pressure. </li>
              <li>💡 <b> Enhances Trade Discipline </b>– Stick to your plan with precision. </li>
              <li>💡 <b> Identifies Trend Phases </b>– Trade with confidence, knowing when to enter and exit. </li>
              <li>💡 <b> Prevents Emotional Trading </b>– Removes fear, greed, and impulsive actions. </li>
              <li>💡 <b> Works Across All Markets </b>– Stocks, Futures, Forex, and Crypto. </li>
              <li>💡 <b> Trade Smart. Trade with Control.</b> </li>
            </ul>

            <div class="course__bottom d-sm-flex justify-content-between align-items-center">
              <div class="course__teacher-2 d-flex align-items-center"></div>
              <div class="course__meta">
                <div [routerLink]="['/plugin-trading']" class="course__lesson btn btn-outline-primary border-radius-0">Subscribe Now</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
        <div class="course__item course__item-2 white-bg transition-3 shadow-lg">
          <!--          <div class="course__thumb fix w-img">-->
          <!--            <a [routerLink]="['/plugin-wave']">-->
          <!--              <img src="assets/img/course/home-3/course-2.jpg" alt="">-->
          <!--            </a>-->
          <!--          </div>-->
          <div class="course__content-2">
            <h3 class="course__title-2"><a [routerLink]="['/plugin-wave']">
                NEoWave Chart Plugin – Redefining Market Forecasting!</a></h3>
            <h4 class="text-secondary">Precision. Structure. Confidence.</h4>
            <p class="mt-15">
              Wave analysis is not just about spotting patterns—it’s about understanding market psychology. The NEoWave
              Chart Plugin brings advanced wave forecasting tools right to your fingertips, making complex market
              structures easy to interpret and act upon.
            </p>

            <h5 class="text-secondary"> Why Choose the NEoWave Chart Plugin?</h5>
            <ul>
              <li>💡 <b> Deciphers Market Waves with Accuracy</b> – No more guesswork, only precision.</li>
              <!-- <li>💡 <b> Pinpoints Reversals Before They Happen</b> – Gain a strategic trading advantage.</li> -->
              <li>💡 <b> Optimized for Multi-Market Analysis</b> – Stocks, Commodities, Forex, Indices.</li>
              <li>💡 <b> Enhances Entry & Exit Precision</b> – Execute trades at the right moment.</li>
              <li>💡 <b> Integrates Glenn Neely’s Advanced Concepts</b> – Designed for serious traders.</li>
              <li>💡 <b>Turn Chaos into Clarity with the NEoWave Chart Plugin!</b></li>
            </ul>

            <div class="course__bottom d-sm-flex justify-content-between align-items-center">
              <div class="course__teacher-2 d-flex align-items-center"></div>
              <div class="course__meta">
                <div [routerLink]="['/plugin-wave']" class="course__lesson btn btn-outline-primary border-radius-0">Subscribe Now</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="row mt-0">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
        <div class="course__item course__item-2 white-bg mb-30 transition-3 shadow-lg mt-30">
          <div class="course__thumb fix w-img shadow-sm border-gray-1">
            <a [routerLink]="['/plugin-fno']">
              <img src="https://www.tradingview.com/x/NY2I33mh/" alt="">
            </a>
          </div>
          <div class="course__content-2">
            <h3 class="course__title-2"><a [routerLink]="['/plugin-fno']">
              Automated Future & Option Trading Plugin for TradingView Platform
            </a></h3>
            <p class="mt-15">
              A Trading system that is designed to be flexible and adaptable to changing market conditions, and it is
              used to trade a variety of asset classes, including stocks, options, futures, and currencies.
            </p>
            <div class="course__bottom d-sm-flex justify-content-between align-items-center">
              <div class="course__teacher-2 d-flex align-items-center"></div>
              <div class="course__meta">
                <div [routerLink]="['/plugin-fno']" class="course__lesson btn btn-outline-primary border-radius-0">Know
                  More
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</section>